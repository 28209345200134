import React from "react";
import { Script } from "gatsby";

const GdprBanner = () => (
  <Script
    id="gtm"
    src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_ID}`}
    strategy="idle"
    onLoad={() => {
      window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({ event: "routeChange" });
      window.dataLayer.push({ event: "gtmLoaded" });
    }}
  />
);
export default GdprBanner;
